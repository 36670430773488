<template>
	<div class="pdpage">
		<div style="height: 40px;">

			<div style="float: right;">
				<el-button type="primary" class="el-icon-refresh" size="small" @click="refreshData">刷新</el-button>
			</div>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>首页</el-breadcrumb-item>
				<el-breadcrumb-item>订单列表</el-breadcrumb-item>

			</el-breadcrumb>
		</div>
		<el-table stripe :data="DataList" style="width: 100%;margin-bottom: 20px; color: #000;" border size="mini">

			<el-table-column type="index" width="80" label="序号">
			</el-table-column>
			<el-table-column prop="file_name" label="文件名称" min-width="100">
				<template scope="scope">
					<span style="cursor: pointer; color: cornflowerblue;"
						@click="downFile(scope.row)">{{scope.row.file_name}}</span>

				</template>
			</el-table-column>
			<el-table-column prop="paper_type" label="打印参数" width="300">
				<template slot-scope="scope">
					<span>
						{{scope.row.paper_type}} /
					</span>
					<span v-if="scope.row.colors==1">
						彩色 /
					</span>
					<span v-else>
						黑白 /
					</span>
					<span v-if="scope.row.double_face==1">
						双面打印 /
					</span>
					<span v-else>
						单面 /
					</span>
					<span>
						{{scope.row.page}}页
					</span>
				</template>
			</el-table-column>

			<el-table-column prop="tmoney" label="支付金额" width="100">
				<template scope="scope">
					<span>
						{{scope.row.tmoney}}元 / {{scope.row.ispay==1?'已支付':''}}{{scope.row.ispay==-1?'已退款':''}}
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="onstate" label="打印状态" width="100">
				<template slot-scope="scope">
					<span v-if="scope.row.print_status==0">
						队列中
					</span>
					<span v-if="scope.row.print_status==1">
						打印中
					</span>
					<span v-if="scope.row.print_status==2">
						打印完成
					</span>
					<span v-if="scope.row.print_status==-1">
						打印失败
					</span>
				</template>
			</el-table-column>

			<el-table-column prop="tmoney" label="备注" width="150">
				<template scope="scope">
					<span>
						{{scope.row.remark}}
					</span>
				</template>
			</el-table-column>


			<el-table-column fixed="right" label="操作" width="100">
				<template slot-scope="scope">
					<el-button v-if="scope.row.ispay==1" type="text" size="small" @click="tuikuan(scope.row)">退款
					</el-button>

					<el-button v-if="scope.row.ispay==1" type="text" size="small" @click="rePrint(scope.row)">重打
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @current-change="handlePageChange" :current-page="page.page" :page-size="page.pagesize"
			layout="total, prev, pager, next, jumper" :total="page.count">
		</el-pagination>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				visibleModal: false,
				easyModalShow: false,
				DataList: [],
				printer: null,
				page: {
					page: 1,
					pagesize: 20,
					count: 0
				},
				timer:0
			}
		},
		mounted() {
			this.getList()
			this.timer = setInterval(()=>{
				this.refreshData()
			},3000)
		},
		beforeUnmount() {
			clearInterval(this.timer)
		},
		methods: {
			getList() {
				this.$http.post("/api/shop_orders", {
					page: this.page.page,
					pagesize:15,
					noloading:1
				}).then(res => {
					this.DataList = res.data.data
					this.page = res.data.page
				})
			},
			handlePageChange(e) {
				this.page.page = e
				this.getList()
			},
			refreshData() {
				this.page.page = 1
				this.getList()
			},
			downFile(e) {
				try{
					e.file_url=JSON.parse(e.file_url)
					e.file_url=e.file_url[0]
				}catch(e){
					//TODO handle the exception
				}
				window.open(e.file_url, "_blank")
			},
			rePrint(e) {
				this.$confirm('确定重打?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/reprint_order", {
						id: e.id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '已加入队列!'
						});
						this.getList()
					})

				})
			},
			tuikuan(e){
				this.$confirm('确定退款?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/wxrefund", {
						id: e.id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '退款成功!'
						});
						this.getList()
					})
				
				})
			}

		}
	}
</script>

<style>
</style>
